import React from "react";
import { Link } from "gatsby";

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const BlogCard = ({ data, mini = false }) => {
  const { image, title, date } = data;
  const blogLink = `/article/${slugify(title)}`;

  if (mini)
    return (
      <div className="mpw_item">
        <img src={image} alt="" />
        <a href={blogLink}>{title}</a>
      </div>
    );

  return (
    <div className="latestBlogItem">
      <div className="lbi_thumb">
        <img src={image} alt={title} />
      </div>
      <div className="lbi_details">
        <Link className="lbid_date" to={blogLink}>
          {date}
        </Link>
        <h2>
          <Link to={blogLink}>{title}</Link>
        </h2>
        <Link className="learnM" to={blogLink}>
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
