import React from "react";

const Paginations = ({ totalPosts, currentPage = 1 }) => {
  const pageNumbers = [];
  const postsPerPage = 6;
  const totalPages = totalPosts > postsPerPage ? Math.ceil(totalPosts / postsPerPage) : 1;

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (currentPage === number) {
      return (
        <span key={number} className={`current`}>
          {number}
        </span>
      );
    } else if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      return (
        <a href='!#' className='page-link'>
          {number}
        </a>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      return (
        <a href='!#' className='page-link'>...</a>
      );
    }
    return null;
  });

  return (
    <div className="meipaly_paginations text-center">
      <a className={`prev page-item${currentPage === 1 ? ' disabled' : ''}`} href="/blog">
        <i className="fa fa-angle-left"></i>
      </a>
      {renderPageNumbers}
      <a className={`next page-item${currentPage === totalPages ? ' disabled' : ''}`} href="/blog">
        <i className="fa fa-angle-right"></i>
      </a>
    </div>
  );
};

export default Paginations;
